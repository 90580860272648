import React from "react";
import { Card, Button } from "react-bootstrap/";
import "./index.css";
import albumsJSON from "../../../photos.json";
import Photos from "../PhotosPage";

const albums = albumsJSON.albums;

class AlbumsPage extends React.Component {
  state = {
    display: "albums",
    albumDes: ""
  };

  viewAlbumHandler = (album, des) => {
    let images = albums.filter(object => {
      return object.name === album;
    });

    this.setState({ display: "album", albumDes: des, album: album });
  };

  backButtonHandler = () => {
    this.setState({ display: "albums", albumDes: "", album: "" });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.display === "albums" ? (
          <React.Fragment>
            {albums.map(album => (
              <Card style={{ width: "18rem" }} className="album">
                <Card.Img variant="top" src={album.cover} />
                <Card.Body>
                  <Card.Title>{album.name}</Card.Title>
                  {/* <Card.Text>album.description</Card.Text> */}
                  <Button
                    className="view-button"
                    onClick={() =>
                      this.viewAlbumHandler(album.name, album.description)
                    }
                  >
                    VIEW ALBUM
                  </Button>
                </Card.Body>
              </Card>
            ))}
          </React.Fragment>
        ) : this.state.display === "album" ? (
          <React.Fragment>
            <Button className="view-button" onClick={this.backButtonHandler}>
              BACK TO ALBUMS
            </Button>
            <Photos albumName={this.state.album} des={this.state.albumDes} />
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }
}

export default AlbumsPage;
