import React from "react";

class PlayVideo extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
        };
        
            return(
                <div>
                    <video className = "video-container video-container-overlay"autoPlay ="true"loop muted = {this.props.muted}> 
                        <source src = {this.props.playVideo}type = "video/mp4" / >
                    </video>
                </div>
            )
        
    }
}

export default PlayVideo;