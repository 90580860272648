import React from "react";
import "./index.css";

function AboutPage(props) {
  return (
    <div className="container">
       <h1 className="text-center"><b>About Us</b></h1>
      <div className="row mt-4 pt-4">
        {/* left column */}
        <div className="col-md-6 text-center">
          <img class="img-fluid about-img-1" src="http://irisandjoe.com/images/AboutUs1_1.jpg" alt="The couple" />
        </div>
        {/* right column */}
        <div className="col-md-6">
         
          <h3><b>Iris</b></h3>
          <p>
            I had no idea the man I was talking to everyday would become my husband... Our first three hours of conversation was all about “Faith”; we used Abraham, Sarah and Joseph stories in the book of Genesis to back up our claims. Joe's interest in the things of GOD made me realized that he is  the one.
            </p>
            <p> Joe and I have never been married; we are a perfect match made in heaven.

            </p>
          
        </div>
      </div>
      <div className="row mt-4 pt-4">
        {/* left column */}
        <div className="col-md-6 text-center">
          <img class="img-fluid about-img-2" src="./images/about2_2.jpg" alt="The couple" />
        </div>
        {/* right column */}
        <div className="col-md-6">
          <h3><b>Joe</b></h3>
          <p>
            As a traditional man, I was looking for a wife and mother with traditional Christian values. Iris was that and more. She welcomes the love I have to give.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
