import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "./index.css"; // This only needs to be imported once in your app

let pathsArr = [];
export default class LightboxExample extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,

      isOpen: false
    };
  }

  renderImages = () => {
    // pathsArr = [];
    let photoIndex = -1;

    // for (let i = 1; i < 185; i++) {
    //   pathsArr.push(`./images/Albums/${this.props.albumName}/${i}.jpg`);
    // }

    if (this.props.albumName === "Bahamas 2019"){
      pathsArr = [
      
        "http://irisandjoe.com/images/Albums/Bahamas 2019/1.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/2.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/3.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/4.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/5.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/6.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/7.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/8.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/9.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/10.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/11.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/12.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/13.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/14.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/15.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/16.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/17.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/18.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/19.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/20.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/21.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/22.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/23.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/24.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/25.jpg", 
        "http://irisandjoe.com/images/Albums/Bahamas 2019/26.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/27.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/28.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/29.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/30.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/31.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/32.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/33.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/34.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/35.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/36.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/37.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/38.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/39.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/40.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/41.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/42.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/43.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/44.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/45.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/46.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/47.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/48.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/49.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/50.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/51.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/52.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/53.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/54.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/55.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/56.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/57.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/58.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/59.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/60.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/61.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/62.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/63.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/64.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/65.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/66.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/67.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/68.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/69.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/70.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/71.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/72.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/73.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/74.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/75.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/76.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/77.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/78.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/79.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/80.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/81.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/82.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/83.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/84.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/85.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/86.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/87.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/88.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/89.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/90.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/91.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/92.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/93.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/94.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/95.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/96.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/97.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/98.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/99.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/100.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/101.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/102.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/103.jpg" ,
        "http://irisandjoe.com/images/Albums/Bahamas 2019/104.jpg" ,
        
      ]
    }

    if (this.props.albumName === "Exotic Eastern Caribbean 2018") {
      pathsArr = [
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/1.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/3.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/5.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/6.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/9.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/10.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/11.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/12.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/13.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/14.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/15.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/16.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/17.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/18.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/19.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/20.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/21.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/22.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/23.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/24.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/25.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/26.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/27.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/28.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/29.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/30.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/32.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/33.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/34.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/35.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/36.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/37.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/38.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/39.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/40.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/41.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/42.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/43.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/44.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/45.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/46.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/47.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/48.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/49.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/50.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/51.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/52.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/53.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/54.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/55.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/56.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/57.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/58.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/59.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/60.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/61.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/62.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/63.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/64.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/65.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/66.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/67.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/68.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/69.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/70.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/71.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/72.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/73.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/74.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/75.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/76.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/77.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/78.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/79.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/80.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/81.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/82.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/83.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/84.jpg",
        "http://irisandjoe.com/images/Albums/Exotic Eastern Caribbean 2018/85.jpg",
       
    ]
  }
    if (this.props.albumName === "Los Angeles California") {
      pathsArr = [ 
        "http://irisandjoe.com/images/Albums/Los Angeles California/1.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/2.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/3.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/4.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/5.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/6.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/7.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/8.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/9.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/10.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/11.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/12.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/13.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/14.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/15.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/16.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/17.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/18.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/19.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/20.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/21.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/22.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/23.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/24.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/25.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/26.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/27.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/28.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/29.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/30.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/31.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/32.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/33.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/34.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/35.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/36.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/37.jpg",
        "http://irisandjoe.com/images/Albums/Los Angeles California/38.jpg",
        

      ]
    }

    if (this.props.albumName === "Miscellaneous") {
      pathsArr = [
        "http://irisandjoe.com/images/Albums/Miscellaneous/1.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/2.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/3.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/4.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/5.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/6.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/8.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/9.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/10.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/11.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/12.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/13.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/14.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/15.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/16.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/18.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/19.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/21.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/23.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/25.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/26.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/27.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/28.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/29.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/30.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/31.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/32.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/33.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/34.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/35.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/36.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/37.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/38.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/39.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/40.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/41.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/42.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/43.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/44.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/45.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/46.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/47.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/48.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/49.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/50.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/51.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/52.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/53.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/54.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/55.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/56.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/57.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/58.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/59.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/60.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/61.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/62.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/63.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/64.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/65.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/66.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/67.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/68.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/69.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/70.jpg",
        "http://irisandjoe.com/images/Albums/Miscellaneous/71.jpg",
        
      ]
    }
    if (this.props.albumName === "Our Family Meeting") {
      pathsArr = [
        "http://irisandjoe.com/images/Albums/Our Family Meeting/1.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/2.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/3.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/4.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/5.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/6.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/7.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/8.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/9.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/10.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/11.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/12.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/14.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/15.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/16.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/17.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/18.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/19.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/20.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/21.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/22.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/23.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/24.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/25.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/26.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/27.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/28.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/29.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/30.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/32.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/33.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/34.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/35.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/36.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/37.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/38.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/39.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/40.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/41.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/42.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/43.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/44.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/45.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/46.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/47.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/48.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/49.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/50.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/51.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/52.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/53.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/54.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/55.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/56.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/57.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/58.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/59.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/60.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/61.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/62.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/63.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/64.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/65.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/66.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/67.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/68.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/69.jpg",
        "http://irisandjoe.com/images/Albums/Our Family Meeting/70.jpg",
        
      ]
    }
    if (this.props.albumName === "Proposal") {
      pathsArr = [
        "http://irisandjoe.com/images/Albums/Proposal/1.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/2.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/3.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/4.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/6.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/7.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/8.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/9.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/10.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/11.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/12.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/13.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/14.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/15.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/16.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/17.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/18.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/19.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/20.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/21.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/22.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/23.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/24.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/25.jpg",
        "http://irisandjoe.com/images/Albums/Proposal/26.jpg",
       
        
      ]
    }
    return pathsArr.map(url => {
      photoIndex++;
      const key = photoIndex;
      return (
        <div
          class="mb-3 pics animation all 1"
          onClick={() => this.setState({ isOpen: true, photoIndex: key })}
        >
          <img
            class="img-fluid"
            src={url}
            alt="Card image cap"
            style={{ display: true, }}
            onError={e => {
              e.target.onerror = null;
              e.target.style.display = "none";
            }}
          />
        </div>
      );
    });
  };
  render() {
    const { photoIndex, isOpen } = this.state;

    return (
      <div class="photos-page">
     
        <h2 style={{ textAlign: "center" }}>{this.props.albumName}</h2>
        <p style={{ textAlign: "center" }} >{this.props.des}</p>
        <br />

        <div class="gallery" id="gallery">
          {this.renderImages()}
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={pathsArr[photoIndex]}
            nextSrc={pathsArr[(photoIndex + 1) % pathsArr.length]}
            prevSrc={
              pathsArr[(photoIndex + pathsArr.length - 1) % pathsArr.length]
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + pathsArr.length - 1) % pathsArr.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % pathsArr.length
              })
            }
          />
        )}
      </div>
    );
  }
}


/* 
        Exotic Pics2

      http://irisandjoe.com/images/Albums/37.jpg
      http://irisandjoe.com/images/Albums/38.jpg
      http://irisandjoe.com/images/Albums/39.jpg
      http://irisandjoe.com/images/Albums/40.jpg
      http://irisandjoe.com/images/Albums/41.jpg
      http://irisandjoe.com/images/Albums/42.jpg
      http://irisandjoe.com/images/Albums/43.jpg
      http://irisandjoe.com/images/Albums/44.jpg
      http://irisandjoe.com/images/Albums/45.jpg
      http://irisandjoe.com/images/Albums/46.jpg
      http://irisandjoe.com/images/Albums/47.jpg
      http://irisandjoe.com/images/Albums/48.jpg
      http://irisandjoe.com/images/Albums/50.jpg
      http://irisandjoe.com/images/Albums/51.jpg
      http://irisandjoe.com/images/Albums/52.jpg
      http://irisandjoe.com/images/Albums/53.jpg
      http://irisandjoe.com/images/Albums/54.jpg
      http://irisandjoe.com/images/Albums/55.jpg
      http://irisandjoe.com/images/Albums/56.jpg
      http://irisandjoe.com/images/Albums/57.jpg
      http://irisandjoe.com/images/Albums/58.jpg
      http://irisandjoe.com/images/Albums/59.jpg
      http://irisandjoe.com/images/Albums/60.jpg
      http://irisandjoe.com/images/Albums/61.jpg
      http://irisandjoe.com/images/Albums/62.jpg
      http://irisandjoe.com/images/Albums/63.jpg
      http://irisandjoe.com/images/Albums/64.jpg
      http://irisandjoe.com/images/Albums/65.jpg
      http://irisandjoe.com/images/Albums/66.jpg
      http://irisandjoe.com/images/Albums/67.jpg
      http://irisandjoe.com/images/Albums/68.jpg
      http://irisandjoe.com/images/Albums/69.jpg
      http://irisandjoe.com/images/Albums/70.jpg
      http://irisandjoe.com/images/Albums/71.jpg
      http://irisandjoe.com/images/Albums/72.jpg
      http://irisandjoe.com/images/Albums/73.jpg
      http://irisandjoe.com/images/Albums/74.jpg
      http://irisandjoe.com/images/Albums/75.jpg
      http://irisandjoe.com/images/Albums/76.jpg
      http://irisandjoe.com/images/Albums/77.jpg
      http://irisandjoe.com/images/Albums/78.jpg
      http://irisandjoe.com/images/Albums/79.jpg
      http://irisandjoe.com/images/Albums/80.jpg
      
*/