import React from "react";
import "./index.css";

function WelcomePage(props) {
  return (
    <div className="container">
    <img class="landing-background"></img>
      <div className="row mt-4 pt-4">
        <div className="col-md-12 welcome">
          <h2><b>Iris & Joe</b></h2>
          <p><b>April 20, 2019, Maryland</b></p>
          <p><b>July 6, 2019, Las Vegas</b></p>
          <img class="img-fluid landing-img" src="http://irisandjoe.com/images/landpage7.jpg" alt="welcome" />
          
          <p class="verse">
            <span class="red-text"><b>Genesis 2:18</b>(NIV)</span> <b><em>The Lord God said, “It is not good for the man to
            be alone. I will make a helper suitable for him.</em></b>
          </p>
        </div>
      </div>
    </div>
  );
}

export default WelcomePage;
