import React from "react";
import "./index.css";

function ProposalPage(props) {
  return (
    <div>
      <h1 className="text-center"><b>Joe Proposes To Iris <br/> December, 2nd 2018<br/>Marina Del, California</b></h1>
      <div className="row mt-4 pt-4">
        {/* left column */}
        <div className="col-md-6 text-center">
          <img
            class="img-fluid"
            src="./images/proposal.jpg"
            alt="marriage proposal"
          />
        </div>
        {/* right column */}
        <div className="col">
          <h3>
            <b>Iris</b>
          </h3>
          <p>
            {" "}
            Joe surprised me with a gorgeous diamond ring at the Marina del Rey
            Hotel in California in front of my family and friends. I was
            astounded about how I did not see that coming. I thought Joe would
            propose on our next trip in the Bahamas, but I was wrong...
          </p>
          <h3>
            <b>Joe</b>
          </h3>
          <p>
            {" "}
            I planned and carried out a surprise proposal in front of Iris’s
            mother, sisters and close friends (15 of us). I Reserved seating for
            16 outside around a high-end fire pit at the posh Marina Del Rey
            hotel. I had a videographer and photographer covering the entire
            event by firelight.{" "}
          </p>
          <p>
            After everyone had a drink (most of the devout Christians were
            drinking hot chocolate) and were snug in their shared blankets that
            looked like two huge snuggies, her gorgeous model sister Tahirih had
            us stand while she sang a beautiful love song dedicated to us. (Even
            at this point Iris had no clue. I mean when you are as fabulous as
            her, a world class serenade and swooning paparazzi is a normal day
            in the office! ;) )
          </p>
          <p>
            Then I proposed a toast. I ended the toast with the most praise to
            Iris and how she supports/believes with me. Closing with how she is
            the best woman I’ve met and giving all the glory to God, I bent the
            knee and presented her with a gorgeous ring with a proposal to
            marry.
          </p>
          <p>
            We concluded the evening as an extended family in the posh
            restaurant around a solid oaktable made for 16. I led the prayer
            that ended with a resounding amen. After dinner was over, Iris,
            Sophie and I ended the evening by the heated pool we had all to
            ourselves.
          </p>
        </div>
      </div>
      <div className="row mt-4 pt-4">
              <div className="col-md-12">
 < video width = "100%" height = "auto" controls poster = "./images/Albums/Proposal/14.jpg" >
   <source src = "http://irisandjoe.com/IJ.mp4" type = "video/mp4" / > Your browser does not support the video tag. </video>
  
              </div>
      </div>
    </div>
  );
}

export default ProposalPage;
