import React from "react";
import "./index.css";

function Navbar(props) {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      {/* heart shaped logo in far right of navbar */}
      <div class="logo">
        <img
          src="./images/logo.png"
          className="img-fluid logo-inner spin"
          alt="heart shaped locket with couple inside"
        />
      </div>
      {/* Main header on navbar, also link back to landing page. */}
      <a
        className="navbar-brand px-2 mx-2"
        onClick={() => props.setpage("Landing")}
      >
        <h1><b>Iris and Joe Wedding</b></h1>
      </a>

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          {props.pages.map(page => {
            return (
              <li className="nav-item">
                <a className="nav-link" onClick={() => props.setpage(page)}>
                  {page} <span className="sr-only">(current)</span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>

      {props.music ? (
        <img
          id="music-toggle"
          onClick={props.togglemusic}
          alt="pause button"
          src="./images/pause.png"
        />
      ) : (
        <img
          id="music-toggle"
          onClick={props.togglemusic}
          alt="play button"
          src="./images/play.png"
        />
      )}
    </nav>
  );
}

export default Navbar;
