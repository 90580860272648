import React from 'react'
import './index.css'

export default function index() {
  return (
    <div>
     <div className="row mt-4 pt-4">
        {/* left column */}
        <div className="col-md-6 text-center">
          <img
            class="img-fluid"
            src="./images/welcome.jpg"
            alt="wedding details"
          />
        </div>
        {/* right column */}
        <div className="col-md-6 text-center welcome-text">
        <p><b>Hi there, thanks for checking out our website! Here you will find helpful resources for all of the need-to-know details in the lead up to our big day. We can’t wait to celebrate this special day with you all.</b></p>
        <p><b><em>Lots of love, Iris and Joe</em></b></p>
        </div>
      </div>
    </div>
  )
}
