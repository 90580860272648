import React from "react";
import Wrapper from "./components/Wrapper";
import Navbar from "./components/Navbar";
import LandingPage from "./components/Pages/LandingPage";
import WelcomePage from "./components/Pages/WelcomePage";
import AboutPage from "./components/Pages/AboutPage";
import ProposalPage from "./components/Pages/ProposalPage";
import AlbumsPage from "./components/Pages/AlbumsPage";
import DetailsPage from "./components/Pages/DetailsPage";
import AccommodationsPage from "./components/Pages/AccommodationsPage";
import PlayVideo from "./components/PlayVideo";

import "./index.css";

class App extends React.Component {
  state = {
    music: true,
    page: "Landing",
    pages: [
      "Welcome",
      "About Us",
      "The Proposal",
      "Photos",
      "Wedding Details",
      "Accommodations"
    ],
  };

  setpage = page => this.setState({ page: page });

  togglemusic = () => this.setState({ music: !this.state.music });

  render() {
    return (
      <div>
<img className="background-image"></img>

        {/* Here we display the navbar, and we give the navbar access to the state and functions related to the current page and the music. The navbar gives the user controls for the current page, and whether the music is playing or not. */}
        <Navbar
          setpage     = {this.setpage}
          pages       = {this.state.pages}
          togglemusic = {this.togglemusic}
          music       = {this.state.music}
        />
        {/* If the current page is the photos page, add two linebreaks above the page. This is because the photos page for some reason does not have a top margin. */}
        <Wrapper>
          {this.state.page === "Photos" ? (
            <div>
              <br />
              <br />
            </div>
          ) : null}
          < img
          class = "background-image"
          src = {
            //background image logic
            this.state.page === "Landing" ?
            "https://images.unsplash.com/photo-1521309918586-feb7aa79a61b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1868&q=80" :
              this.state.page === "Welcome" ?
              "https://images.unsplash.com/photo-1521309918586-feb7aa79a61b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1868&q=80" :
              this.state.page === "About Us" ?
              "https://images.unsplash.com/photo-1511184150666-9bb7d41a88f4?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80" :
              this.state.page === "The Proposal" ?
              "https://images.unsplash.com/photo-1506668853927-740b51733a32?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80" :
              this.state.page === "Photos" ?
              "https://images.unsplash.com/photo-1521309918586-feb7aa79a61b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1868&q=80" :
              this.state.page === "Wedding Details" ?
              "https://images.unsplash.com/photo-1521309918586-feb7aa79a61b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1868&q=80" :
              this.state.page === "Accommodations" ?
              "https://images.unsplash.com/photo-1521309918586-feb7aa79a61b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1868&q=80" :
              null
          }
          />
          <div className="container">
            <div className="row">
            {/* Here is the routing. A different page is displayed in the page depending on what the value of state.page */}
              { this.state.page === "Landing" ? <LandingPage />
              : this.state.page === "Welcome" ? <WelcomePage />
              : this.state.page === "About Us" ? <AboutPage />
              : this.state.page === "The Proposal" ? <ProposalPage />
              : this.state.page === "Photos" ? <AlbumsPage />
              : this.state.page === "Wedding Details" ? <DetailsPage />
              : this.state.page === "Accommodations" ? <AccommodationsPage />
              : null}
            </div>
          </div>
        </Wrapper>

            {/* This is the invisible youtube window that is playing the music. This element is only present when state.music is true. */}
        {this.state.music ? (
          <iframe
            title="Background Music"
            class="hide"
            width="50"
            height="315"
            src="https://www.youtube.com/embed/BK4kEcOLVI0?start=6;autoplay=1"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        ) : null}
      </div>
    );
  }
}

export default App;
