import React from "react";
import "./index.css";

function DetailsPage(props) {
  return (
    <div>
      <div className="row mt-4 pt-4">
        {/* left column */}
        <div className="col-md-6">
          <img
            class="img-fluid nina d-block mx-auto"
            src="images/nina8.jpeg"
           /* src="http://irisandjoe.com/images/nina8.jpeg"*/
            alt="wedding details" 
          />
        </div>
        {/* right column */}
        <div className="col-md-6">
        <h3>Wedding Party Roles and Responsibilities</h3>
          <p><b>Wedding Planner and Coordinator: ​Nina Toche</b></p>
          <p><b>Matron of Honor:​ Martine Tuendem</b></p>
          <p><b>Maid of Honor:​ Tahirih Toche</b></p>
          <p><b>Best man:​ Mike John</b></p>
          <p><b>Bridesmaids:​ Augusta, Carine, Claude, Elom, Nadege, Sandrine, Olga, Yolanda, Martial</b></p>
        </div>
      </div>
      <div className="row mt-4 pt-4">
        {/* left column */}
        <div className="col-md-6">
          <img
            class="img-fluid d-block mx-auto"
            src="./images/TraditionalAfricanWedding.jpeg"
            /* src="http://irisandjoe.com/images/TradionalAfricanWedding.jpeg"*/
            alt="wedding details"
          />
        </div>
        {/* right column */}
        <div className="col-md-6">
        <h3>Traditional African Wedding</h3>
         <ul><b>
           <li>Date: April 20</li>
           <li>Time: 8PM</li>
           <li>Location: Martin’s Camelot</li>
           <li>Address: 13905 Central Ave, Upper Marlboro, MD 20774</li></b>
         </ul>
        </div>
      </div>
      <div className="row mt-4 pt-4">
        {/* left column */}
        <div className="col-md-6">
          <img
            class="img-fluid d-block mx-auto"
            src="./images/traditionalWedding.jpeg"
            /*src="http://irisandjoe.com/images/traditionalWedding.jpeg"*/
            alt="wedding details"
          />
        </div>
        {/* right column */}
        <div className="col-md-6">
        <h3>Traditional Wedding</h3>
         <ul><b>
           <li>Date: July 6</li>
           <li>Ceremony: 5 PM in the Classico Chapel</li>
           <li>Reception: 6:30 PM in the Emperor Ballroom</li>
           <li>Location: Caesar Palace</li>
           <li>Address: 3570 S Las Vegas Blvd, Las Vegas, NV 89109</li></b>
         </ul>
        </div>
      </div>
    </div>
  );
}

export default DetailsPage;
