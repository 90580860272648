import React from "react";
import "./index.css";

function AccommodationsPage(props) {
  return (
    <div className="container">
      <div className="row mt-4 pt-4">
        <div className="col-md-6">
          <h1><b>Accommodations</b></h1>
          <p className="a1">
            {" "}
            For those of you who plan to join us in Las Vegas for the wedding,
            you'll find travel planning details and resort information
            below. Depending on your preference, budget, and length of stay,
            there are 2 options for accommodations.
          </p>
          <p className="a2">
            {" "}
            Please contact Nina, she can
            help you with booking your room and finding the best flight options
            for you. She is aware of sales opportunities, flight credits and
            more, ensuring you get the best deal possible. It is highly
            recommended that you book your travel as soon as possible to secure
            the best rates.
          </p>
        </div>
        <div className="col-md-6">
        <img class="img-fluid" src="https://66.media.tumblr.com/124fda11aaa2b4943a6085bdb6aca269/tumblr_nhgk2h4CRI1u4ta7do1_r1_1280.gifv" />
        </div>
      </div>
    </div>
  );
}

export default AccommodationsPage;
